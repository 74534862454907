<template>
	<modal class="NoxModalCropper" name="NoxModalCropper" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxCropperType == 'cropImageForAvatar'" v-html="$store.getters.getLanguageText('5.1', 4)"></span>
					<span v-else-if="noxCropperType == 'cropImageForVerification'" v-html="$store.getters.getLanguageText('5.1', 0)"></span>
					<span v-else-if="noxCropperType == 'cropImageForAddLandingsElement'">Добавление элемента</span>
					<span v-else-if="noxCropperType == 'cropImageForEditLandingsElement'">Редактирование фото элемента</span>
					<span v-else v-html="$store.getters.getLanguageText('5.1', 1)"></span>
				</div>
			</div>
			<div class="nox_modal_body">
				<form class="nox_modal_form">
					<div class="nox_modal_info">
						<div class="nox_modal_info_row">
							<div class="nox_cropper">
								<div class="nox_cropper_content">
									<div class="nox_cropper_content_data"></div>
									<div class="nox_cropper_content_crop">
										<vue-cropper ref="cropper" :src="noxCropperImage" :aspectRatio="noxCropperAspectRatio" preview=".preview"></vue-cropper>
									</div>
									<div class="nox_cropper_content_alert" v-html="noxAlertCropperImage"></div>
								</div>
							</div>
						</div>
						<div class="nox_modal_info_rows margin_top_15" v-if="noxCropperType == 'cropImageForVerification'">
							<div class="nox_modal_info_row last">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.1', 2)"></div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxDocument" :placeholder="$store.getters.getLanguageText('5.1', 3)" autocomplete="off">
									<div v-html="noxAlertDocument"></div>
								</div>
							</div>
						</div>
						<div class="nox_modal_info_rows margin_top_15" v-else-if="noxCropperType == 'cropImageForAddLandingsElement'">
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Код элемента:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxLandingsElementCode" placeholder="код элемента" autocomplete="off">
									<div v-html="noxAlertLandingsElementCode"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Приоритет элемента:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxLandingsElementPriority" placeholder="приоритет элемента" autocomplete="off">
									<div v-html="noxAlertLandingsElementPriority"></div>
								</div>
							</div>
							<div class="nox_modal_info_row last">
								<div class="nox_modal_info_row_label">Статус элемента:</div>
								<div class="nox_modal_info_row_value">
									<toggle-button class="nox_toggle_button" :value="toggleValue(noxLandingsElementIsActive)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForLandingsElementIsActive"></toggle-button>
									<div v-html="noxAlertLandingsElementIsActive"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
						<button type="submit" class="nox_button common green" @click="save" v-if="noxCropperType == 'cropImageForVerification'" v-html="$store.getters.getLanguageText('1.1', 45)"></button>
						<button type="submit" class="nox_button common green" @click="save" v-else-if="noxCropperType == 'cropImageForAddLandingsElement'" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
						<button type="submit" class="nox_button common green" @click="save" v-else v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					</div>
				</form>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertDocument: '',
			noxAlertCropperImage: '',
			noxAlertLandingsElementCode: '',
			noxAlertLandingsElementPriority: '',
			noxAlertLandingsElementIsActive: '',
			noxCropperType: '',
			noxCropperData: {},
			noxCropperImage: '',
			noxCropperFormData: {},
			noxCropperAspectRatio: NaN,
			noxDocument: '',
			noxLandingsElementCode: '',
			noxLandingsElementPriority: '',
			noxLandingsElementIsActive: 0,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxCropperType = event.params.type;
				this.noxCropperData = event.params.data;
				this.noxCropperImage = event.params.image;
				this.noxCropperFormData = {};
				this.noxCropperAspectRatio = NaN;
				this.noxDocument = '';
				this.noxLandingsElementCode = '';
				this.noxLandingsElementPriority = '';
				this.noxLandingsElementIsActive = 0;
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxCropperType == 'cropImageForAvatar') {
					this.noxCropperAspectRatio = 1;
				}
				else if (this.noxCropperType == 'cropImageForVerification') {
					this.$modal.hide('NoxModalVerification');
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertDocument = '';
				this.noxAlertCropperImage = '';
				this.noxAlertLandingsElementCode = '';
				this.noxAlertLandingsElementPriority = '';
				this.noxAlertLandingsElementIsActive = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 2); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 3); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 4); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 5); }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 6); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 7); }
				else if (i == 9) { this.noxTemp = this.$store.getters.getLanguageText('1.3.10', 8); }
				else if (i == 10) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 19); }
				else if (i == 11) { this.noxTemp = this.$store.getters.getLanguageText('1.3.8', 20); }
				else if (i == 12) { this.noxTemp = 'Необходимо указать id элемента.'; }
				else if (i == 13) { this.noxTemp = 'Необходимо указать верный id элемента.'; }
				else if (i == 14) { this.noxTemp = 'Необходимо выбрать изображение элемента.'; }
				else if (i == 15) { this.noxTemp = 'Необходимо выбрать верное изображение элемента. Поддерживаемый формат: JPG, PNG, JPEG. Максимальный размер: 2 мегабайта.'; }
				else if (i == 16) { this.noxTemp = 'Необходимо указать код элемента.'; }
				else if (i == 17) { this.noxTemp = 'Необходимо указать верный код элемента.'; }
				else if (i == 18) { this.noxTemp = 'Необходимо указать приоритет элемента.'; }
				else if (i == 19) { this.noxTemp = 'Необходимо указать верный приоритет элемента.'; }
				else if (i == 20) { this.noxTemp = 'Необходимо указать статус элемента.'; }
				else if (i == 21) { this.noxTemp = 'Необходимо указать верный статус элемента.'; }
				else if (i == 22) { this.noxTemp = 'Необходимо указать id блока.'; }
				else if (i == 23) { this.noxTemp = 'Необходимо указать верный id блока.'; }
				else if (i == 24) { this.noxTemp = 'Необходимо выбрать изображение блока.'; }
				else if (i == 25) { this.noxTemp = 'Необходимо выбрать верное изображение блока. Поддерживаемый формат: JPG, PNG, JPEG. Максимальный размер: 2 мегабайта.'; }
				else if (i == 26) { this.noxTemp = 'Такой приоритет элемента уже есть в системе.'; }
				else if (i == 27) { this.noxTemp = 'Id элемента не найден в системе.'; }
				else if (i == 28) { this.noxTemp = 'Id блока не найден в системе.'; }
				else              { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			save: function(event) {
				event.preventDefault(); var _this = this;
				_this.noxCropperFormData = new FormData();
				if (_this.noxCropperType == 'cropImageForAvatar') {
					_this.$refs.cropper.getCroppedCanvas({ width: 130, height: 130, fillColor: '#fff', imageSmoothingQuality: 'high' }).toBlob(function(blob) {
						_this.noxCropperFormData.append('blob', blob);
						_this.axios('save');
					});
				}
				else if (_this.noxCropperType == 'cropImageForVerification') {
					_this.$refs.cropper.getCroppedCanvas({ maxWidth: 1000, maxHeight: 1000, fillColor: '#fff' }).toBlob(function(blob1) {
						_this.$refs.cropper.getCroppedCanvas({ width: 200, height: 200, fillColor: '#fff', imageSmoothingQuality: 'high' }).toBlob(function(blob2) {
							_this.noxCropperFormData.append('blob1', blob1);
							_this.noxCropperFormData.append('blob2', blob2);
							_this.axios('save');
						});
					});
				}
				else if (['cropImageForAddLandingsElement', 'cropImageForEditLandingsElement'].includes(_this.noxCropperType)) {
					_this.$refs.cropper.getCroppedCanvas({ maxWidth: 2000, maxHeight: 2000, fillColor: '#fff', imageSmoothingQuality: 'high' }).toBlob(function(blob1) {
						_this.$refs.cropper.getCroppedCanvas({ maxWidth: 1000, maxHeight: 1000, fillColor: '#fff', imageSmoothingQuality: 'high' }).toBlob(function(blob2) {
							_this.noxCropperFormData.append('blob1', blob1);
							_this.noxCropperFormData.append('blob2', blob2);
							_this.axios('save');
						});
					});
				}
				else if (_this.noxCropperType == 'cropImageForTelegramBot') {
					_this.$refs.cropper.getCroppedCanvas({ maxWidth: 1000, maxHeight: 1000, fillColor: '#fff' }).toBlob(function(blob) {
						_this.noxCropperFormData.append('blob', blob);
						_this.axios('save');
					});
				}
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (type == 'save') {
						if (_this.noxCropperType == 'cropImageForAvatar') {
							config.url = '/v2/account/users/avatar';
						}
						else if (_this.noxCropperType == 'cropImageForVerification') {
							if (!_this.noxDocument) {
								_this.noxAlertDocument = _this.getError(3);
							}
							else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxDocument)) {
								_this.noxAlertDocument = _this.getError(4);
							}
							if (!_this.noxIsError) {
								config.url = '/v2/account/verification';
								_this.noxCropperFormData.append('document', _this.noxDocument);
							}
						}
						else if (_this.noxCropperType == 'cropImageForAddLandingsElement') {
							if (!_this.noxLandingsElementCode) {
								_this.noxAlertLandingsElementCode = _this.getError(16);
							}
							else if (!_this.$store.state.noxRegex_w.test(_this.noxLandingsElementCode) || _this.noxLandingsElementCode.length > 10) {
								_this.noxAlertLandingsElementCode = _this.getError(17);
							}
							if (!_this.noxLandingsElementPriority) {
								_this.noxAlertLandingsElementPriority = _this.getError(18);
							}
							else if (!_this.$store.state.noxRegex_d.test(_this.noxLandingsElementPriority) || _this.noxLandingsElementPriority < 1) {
								_this.noxAlertLandingsElementPriority = _this.getError(19);
							}
							if (!_this.noxIsError) {
								config.url = '/v2/admin/account/landings/elements';
								_this.noxCropperFormData.append('code', _this.noxLandingsElementCode);
								_this.noxCropperFormData.append('priority', _this.noxLandingsElementPriority);
								_this.noxCropperFormData.append('is_active', _this.noxLandingsElementIsActive);
							}
						}
						else if (_this.noxCropperType == 'cropImageForEditLandingsElement') {
							config.url = '/v2/admin/account/landings/elements/image';
							_this.noxCropperFormData.append('id', _this.noxCropperData.id);
						}
						else if (_this.noxCropperType == 'cropImageForTelegramBot') {
							config.url = '/v2/admin/account/alerts/tb/blocks/photo';
							_this.noxCropperFormData.append('id', _this.noxCropperData.bid);
						}
						if (!_this.noxIsError) {
							config.data = _this.noxCropperFormData;
							config.method = 'post';
							config.headers['Content-Type'] = 'multipart/form-data';
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'save') {
									if (_this.noxCropperType == 'cropImageForAvatar') {
										if (data.data) { _this.$store.state.noxAccountData.avatar = data.data; }
										if (data.data && _this.$route.path.indexOf('/account/client_program') >= 0) {
											_this.$parent.noxTree.depth0[0].user_data.avatar = data.data;
										}
									}
									else if (_this.noxCropperType == 'cropImageForVerification') {
										_this.$parent.noxVerificationsData.unshift(data.data);
										_this.$parent.noxVerificationsDataLimitAdded++;
										_this.$parent.noxIsVerificationAdd = false;
									}
									else if (_this.noxCropperType == 'cropImageForAddLandingsElement') {
										_this.$parent.noxLandingsElementsData.push(data.data);
									}
									else if (_this.noxCropperType == 'cropImageForEditLandingsElement') {
										_this.$parent.noxLandingsElementsData.splice(_this.noxCropperData.index, 1, data.data);
									}
									else if (_this.noxCropperType == 'cropImageForTelegramBot') {
										if (data.data) { _this.$set(_this.$store.state.noxTBData[_this.noxCropperData.cid].blocks['id' + _this.noxCropperData.bid].data.details, 'photo', data.data); }
									}
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (_this.noxCropperType == 'cropImageForAvatar') {
										if      (data.response.data.error == 'BLOB_IS_EMPTY') { _this.noxAlertCropperImage = _this.getError(10); }
										else if (data.response.data.error == 'BLOB_SIZE_NOT_VALID') { _this.noxAlertCropperImage = _this.getError(11); }
										else if (data.response.data.error == 'BLOB_TYPE_NOT_VALID') { _this.noxAlertCropperImage = _this.getError(11); }
									}
									else if (_this.noxCropperType == 'cropImageForVerification') {
										if      (data.response.data.error == 'BLOB_1_IS_EMPTY') { _this.noxAlertCropperImage = _this.getError(1); }
										else if (data.response.data.error == 'BLOB_1_SIZE_NOT_VALID') { _this.noxAlertCropperImage = _this.getError(2); }
										else if (data.response.data.error == 'BLOB_1_TYPE_NOT_VALID') { _this.noxAlertCropperImage = _this.getError(2); }
										else if (data.response.data.error == 'BLOB_2_IS_EMPTY') { _this.noxAlertCropperImage = _this.getError(1); }
										else if (data.response.data.error == 'BLOB_2_SIZE_NOT_VALID') { _this.noxAlertCropperImage = _this.getError(2); }
										else if (data.response.data.error == 'BLOB_2_TYPE_NOT_VALID') { _this.noxAlertCropperImage = _this.getError(2); }
										else if (data.response.data.error == 'DOCUMENT_IS_EMPTY') { _this.noxAlertDocument = _this.getError(3); }
										else if (data.response.data.error == 'DOCUMENT_NOT_VALID') { _this.noxAlertDocument = _this.getError(4); }
									}
									else if (['cropImageForAddLandingsElement', 'cropImageForEditLandingsElement'].includes(_this.noxCropperType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(12); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(13); }
										else if (data.response.data.error == 'BLOB_1_IS_EMPTY') { _this.noxAlertCropperImage = _this.getError(14); }
										else if (data.response.data.error == 'BLOB_1_SIZE_NOT_VALID') { _this.noxAlertCropperImage = _this.getError(15); }
										else if (data.response.data.error == 'BLOB_1_TYPE_NOT_VALID') { _this.noxAlertCropperImage = _this.getError(15); }
										else if (data.response.data.error == 'BLOB_2_IS_EMPTY') { _this.noxAlertCropperImage = _this.getError(14); }
										else if (data.response.data.error == 'BLOB_2_SIZE_NOT_VALID') { _this.noxAlertCropperImage = _this.getError(15); }
										else if (data.response.data.error == 'BLOB_2_TYPE_NOT_VALID') { _this.noxAlertCropperImage = _this.getError(15); }
										else if (data.response.data.error == 'CODE_IS_EMPTY') { _this.noxAlertLandingsElementCode = _this.getError(16); }
										else if (data.response.data.error == 'CODE_NOT_VALID') { _this.noxAlertLandingsElementCode = _this.getError(17); }
										else if (data.response.data.error == 'PRIORITY_IS_EMPTY') { _this.noxAlertLandingsElementPriority = _this.getError(18); }
										else if (data.response.data.error == 'PRIORITY_NOT_VALID') { _this.noxAlertLandingsElementPriority = _this.getError(19); }
										else if (data.response.data.error == 'IS_ACTIVE_IS_EMPTY') { _this.noxAlertLandingsElementIsActive = _this.getError(20); }
										else if (data.response.data.error == 'IS_ACTIVE_NOT_VALID') { _this.noxAlertLandingsElementIsActive = _this.getError(21); }
									}
									else if (_this.noxCropperType == 'cropImageForTelegramBot') {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(22); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(23); }
										else if (data.response.data.error == 'BLOB_IS_EMPTY') { _this.noxAlertCropperImage = _this.getError(24); }
										else if (data.response.data.error == 'BLOB_SIZE_NOT_VALID') { _this.noxAlertCropperImage = _this.getError(25); }
										else if (data.response.data.error == 'BLOB_TYPE_NOT_VALID') { _this.noxAlertCropperImage = _this.getError(25); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (_this.noxCropperType == 'cropImageForTelegramBot') {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(28); }
									}
									else if (['cropImageForAddLandingsElement', 'cropImageForEditLandingsElement'].includes(_this.noxCropperType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(27); }
									}
								}
								else if (data.response.status == 409) {
									if (_this.noxCropperType == 'cropImageForVerification') {
										if      (data.response.data.error == 'ALREADY_ADDED') { _this.noxAlert = _this.getError(5); }
										else if (data.response.data.error == 'ALREADY_APPROVED') { _this.noxAlert = _this.getError(6); }
										else if (data.response.data.error == 'ALREADY_REJECTED') { _this.noxAlert = _this.getError(7); }
										else if (data.response.data.error == 'ALREADY_CANCELED') { _this.noxAlert = _this.getError(8); }
										else if (data.response.data.error == 'ALREADY_BANNED') { _this.noxAlert = _this.getError(9); }
									}
									else if (['cropImageForAddLandingsElement', 'cropImageForEditLandingsElement'].includes(_this.noxCropperType)) {
										if (data.response.data.error == 'PRIORITY_DUPLICATE') { _this.noxAlertLandingsElementPriority = _this.getError(26); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForLandingsElementIsActive: function(event) {
				this.noxLandingsElementIsActive = Number(event.value);
			},
			close: function() {
				this.$modal.hide('NoxModalCropper');
			}
		}
	}
</script>
